import {
  scaffolderPlugin,
} from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { FrissRepoUrlPicker } from './RepoUrlPicker';
import { repoPickerValidation } from './validation';

export const FrissRepoUrlPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'FrissRepoUrlPicker',
    component: FrissRepoUrlPicker,
    validation: repoPickerValidation,
  }),
);
