import {
  WelcomeTitle,
  HeaderWorldClock,
  ClockConfig,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageCompanyLogo,
  Tool,
  ComponentAccordion,
} from '@backstage/plugin-home';
import { BazaarOverviewCard } from '@backstage/plugin-bazaar';
import {
  Content,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { AnnouncementsCard, NewAnnouncementBanner } from '@procore-oss/backstage-plugin-announcements';

import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import AirportShuttle from '@material-ui/icons/AirportShuttle';
import Bookmark from '@material-ui/icons/Bookmark';
import Euro from '@material-ui/icons/Euro';
import Gavel from '@material-ui/icons/Gavel';
import LinearScale from '@material-ui/icons/LinearScale';
import Lock from '@material-ui/icons/Lock';
import OndemandVideo from '@material-ui/icons/OndemandVideo';
import School from '@material-ui/icons/School';
import WbCloudy from '@material-ui/icons/WbCloudy';
import Dog from '@material-ui/icons/Pets';

const clockConfigs: ClockConfig[] = [
  {
    label: 'NYC',
    timeZone: 'America/New_York',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'AMS',
    timeZone: 'Europe/Amsterdam',
  },
  {
    label: 'TYO',
    timeZone: 'Asia/Tokyo',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

const loggingLinks: Tool[] = [
  {
    url: 'https://app.datadoghq.eu/apm/home',
    label: 'Europe',
    icon: <Dog />
  },
  {
    url: 'https://us3.datadoghq.com/apm/home',
    label: 'US & CA',
    icon: <Dog />
  }
];

const learningLinks: Tool[] = [
  {
    url: 'https://backstage.infra.dev.frisscloud.com/docs/default/component/dev-portal',
    label: 'FRISS Dev Portal',
    icon: <Bookmark />,
  },
  {
    url: 'https://friss.eu.crossknowledge.com/site/home',
    label: 'FRISS Academy',
    icon: <School />,
  },
  {
    url: 'https://learn.microsoft.com/en-us/azure/architecture/',
    label: 'Azure Architecture Center',
    icon: <WbCloudy />,
  },
  {
    url: 'https://learn.microsoft.com/en-us/training/',
    label: 'Microsoft Training',
    icon: <School />,
  },
  {
    url: 'https://www.youtube.com/channel/UC-ikyViYMM69joIAv7dlMsA',
    label: 'DevOps on Azure',
    icon: <OndemandVideo />,
  },
  {
    url: 'https://ssw.com.au/rules/rules-to-better-azure',
    label: 'Rules to Better Azure',
    icon: <Gavel />,
  },
];

const pillarsLinks: Tool[] = [
  {
    url: 'https://docs.microsoft.com/en-us/azure/architecture/framework/resiliency/principles',
    label: 'Reliability',
    icon: <AccessTime />,
  },
  {
    url: 'https://docs.microsoft.com/en-us/azure/architecture/framework/cost/principles',
    label: 'Cost Optimization',
    icon: <Euro />,
  },
  {
    url: 'https://docs.microsoft.com/en-us/azure/architecture/framework/scalability/principles',
    label: 'Scalability',
    icon: <LinearScale />,
  },
  {
    url: 'https://docs.microsoft.com/en-us/azure/architecture/framework/security/security-principles',
    label: 'Security',
    icon: <Lock />,
  },
  {
    url: 'https://docs.microsoft.com/en-us/azure/architecture/framework/devops/principles',
    label: 'DevOps',
    icon: <AirportShuttle />,
  },
];

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const HomePage = () => {
  const { container } = useLogoStyles();
  const classes = useStyles();

  const ExpandedComponentAccordion = (props: any) => (
    <ComponentAccordion expanded {...props} />
  );

  return (
    <Page themeId="home">
      <Header title={<WelcomeTitle />} pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
        <SupportButton />
      </Header>
      <Content>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item md={12}>
            <NewAnnouncementBanner />
          </Grid>
          <HomePageCompanyLogo className={container} />
          <Grid container item xs={12} alignItems="center" direction="row">
            <HomePageSearchBar
              classes={{ root: classes.searchBar }}
              placeholder="Search"
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <HomePageStarredEntities />
            </Grid>
            <Grid item xs={12} md={4}>
              <AnnouncementsCard max={5} />
            </Grid>
            <Grid item xs={12} md={4}>
              <HomePageToolkit
                title="Learning"
                tools={learningLinks}
                Renderer={ExpandedComponentAccordion}
              />
              <HomePageToolkit
                title="The 5 Pillars of Well-Architected Framework"
                tools={pillarsLinks}
                Renderer={ComponentAccordion}
              />
              <HomePageToolkit
                title="Logging"
                tools={loggingLinks}
                Renderer={ComponentAccordion}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <BazaarOverviewCard order="latest" />
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
