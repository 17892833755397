import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';
import { BackstageTheme, createTheme, lightTheme } from '@backstage/theme';

import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';
import { EntityContextMenuClassKey } from '@backstage/plugin-catalog';

// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
    PluginCatalogEntityContextMenu: EntityContextMenuClassKey;
  }
}

const baseTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#FF8000',
      light: '#FDB165',
      dark: '#E55400',
    },
    secondary: {
      main: '#000B4C',
      light: '#646C93',
      dark: '#000025',
    },
    grey: {
      50: '#fafcfc',
      100: '#f5f7f7',
      200: '#f0f2f2',
      300: '#e8eaea',
      400: '#c6c8c8',
      500: '#a8aaaa',
      600: '#7e8080',
      700: '#696b6b',
      800: '#4a4c4c',
      900: '#282a2a',
    },
    error: {
      main: '#FF5630',
      light: '#FF8F73',
      dark: '#DE350B',
    },
    warning: {
      main: '#FFAB00',
      light: '#FFE380',
      dark: '#FF8B00',
    },
    success: {
      main: '#36B37E',
      light: '#79F2C0',
      dark: '#006644',
    },
    info: {
      main: '#0065FF',
      light: '#4C9AFF',
      dark: '#0747A6',
    },
    navigation: {
      ...lightTheme.palette.navigation,
      background: '#000B4C',
      color: '#FFFFFF',
      indicator: '#2684FF',
      navItem: {
        hoverBackground: 'rgba(116,118,121,0.6)',
      },
    },
    text: {
      primary: '#172B48',
    },
    background: {
      default: '#FAFCFC',
    }
  },
  fontFamily: 'proxima-nova, Roboto, sans-serif',
  defaultPageTheme: 'home',
});

const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides & CatalogReactOverrides => {
  return {
    BackstageHeader: {
      header: {
        backgroundImage: 'unset',
        boxShadow: 'unset',
        paddingBottom: theme.spacing(1),
      },
      title: {
        color: theme.palette.primary.dark,
        fontWeight: 900,
      },
      subtitle: {
        color: theme.palette.primary.dark,
      },
      type: {
        color: theme.palette.primary.dark,
      },
    },
    BackstageHeaderLabel: {
      label: {
        color: theme.palette.primary.dark,
      },
      value: {
        color: theme.palette.primary.dark,
      },
    },
    BackstageHeaderTabs: {
      defaultTab: {
        fontSize: 'inherit',
        textTransform: 'none',
      },
    },
    BackstageOpenedDropdown: {
      icon: {
        '& path': {
          fill: '#FFFFFF',
        },
      },
    },
    BackstageTable: {
      root: {
        '&> :first-child': {
          borderBottom: '1px solid #D5D5D5',
          boxShadow: 'none',
        },
        '& th': {
          borderTop: 'none',
          textTransform: 'none !important',
        },
      },
    },
    CatalogReactUserListPicker: {
      root: {
        backgroundColor: theme.palette.grey[200],
      },
      title: {
        textTransform: 'none',
      },
    },
    PluginCatalogEntityContextMenu: {
      button: {
        color: theme.palette.primary.dark,
      },
    },
    BackstageTableFilters: {
      root: {
        marginLeft: 24,
        paddingBottom: 14,
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 0,
      }
    },
    MuiAutocomplete: {
      root: {
        '&[aria-expanded=true]': {
          color: '#FFFFFF',
        },
        '&[aria-expanded=true] path': {
          fill: '#FFFFFF',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(9,30,69,0.54)',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        color: '#fafcfc',
      },
    },
    MuiChip: {
      root: {
        borderRadius: 3,
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.primary.dark,
        margin: 4,
      },
    },
    MuiSelect: {
      root: {
        '&[aria-expanded]': {
          backgroundColor: '#26385A',
          color: '#FFFFFF',
        },
      },
    },
    MuiSwitch: {
      root: {
        padding: 10,
      },
      switchBase: {
        padding: 12,
      },
      thumb: {
        backgroundColor: '#FFFFFF',
        height: 14,
        width: 14,
      },
      track: {
        borderRadius: 9,
      },
    },
    MuiTabs: {
      indicator: {
        transition: 'none',
      },
    },
    MuiTypography: {
      button: {
        textTransform: 'none',
      },
    }
  };
};

export const frissTheme: BackstageTheme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};
